import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { auth } from "../../firebase";
import { logout } from "../../features/userSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      color: "#3e3e3e",
    },
    headerTitle: {
      backgroundColor: "#ffffff",
    },
    logoutButton: {
      width: "100px",
      height: "40px",
      right: "50px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#00CC99",
      "&:hover": {
        background: "#00CC99",
      },
    },
  })
);

const ButtonAppBar: FC<{ titleName: string }> = ({ titleName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDisplayButton = () => {
    return process.env.REACT_APP_MODE === "dev-mail-auth" || process.env.REACT_APP_MODE === "test-mail-auth" 
  }

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.log(`logout error (${error})`);
      });
  }; 

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.headerTitle}>
        <Toolbar>
          <img
            src={`${window.location.origin}/image/sejlogo.png`}
            alt="SEJアイコン"
            style={{ height: "7vh" }}
          />
          <Typography variant="h6" className={classes.title}>
            {titleName}
          </Typography>
          <Button
            className={classes.logoutButton} 
            style={{ display: isDisplayButton() ? "" : "none" }} 
            onClick={() => {signOut();}}
          >
            ログオフ
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default ButtonAppBar;
