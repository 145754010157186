import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Header from "../Header/Header";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../apis/axiosAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { userLogging } from "../../apis/userLog";
import { getDeviceType } from "../../apis/util";

let appInfo: any = {};
const iconDir = `${window.location.origin}/image/icons/`;
const chatBot = "chat-bot";
const opview = "ofc-counselling";
const tenpoCheck = "tenpo-check";
const ofcCounselling = "acn-ofc-counselling";
const ofcDashboard = "ofc-dashboard";
const shopItemTag = "shop-item-tag";
const chatAi = "chatAi";

const getAppInfo = (appName: string, typeName: string): string => {
  if (typeName === "auth-url") {
    return appInfo[appName].auth_url;
  } else if (typeName === "site-url") {
    return appInfo[appName].site_url;
  } else if (typeName === "app-name") {
    return appInfo[appName].app_name;
  } else if (
    typeName === "token" &&
    appName.startsWith(ofcCounselling)
  ) {
    return "usertoken";
  } else if (typeName === "token" && appName.startsWith(shopItemTag)) {
    return "usertoken";
  } else if (typeName === "token" && appName.startsWith(chatAi)) {
    return "usertoken";
  } else if (typeName === "token") {
    return "token";
  }
  return "";
};

// ユーザー種別に応じて利用可能なアプリケーションを設定する
const setApplication = (
  apps: any,
  mail_address: string = "",
  job_category_code_OA: string = "",
  department_code_OA: string = ""
) => {
  // デジタルツール上のボタン非表示
  let exclusionApps: string[] = [];
  // iPhoneの場合
  if (getDeviceType() === "iphone") {
    exclusionApps = [opview, tenpoCheck, ofcCounselling, ofcDashboard];
  } else {
    // その他デバイス
    exclusionApps = [ofcDashboard];
  }
  apps = apps.filter((app: string) => !exclusionApps.includes(app));
  // 職種コード・メールアドレスが存在しない場合既存アプリで返す
  if (mail_address === undefined || job_category_code_OA === undefined) {
    return apps;
  }
  // 特定ユーザーおよびOFC,AFC,DM以外の職種および長野・山梨ZO内DOのOFC,AFC,DMにチャットボット権限付与
  if (
    (job_category_code_OA === "310" ||
      job_category_code_OA === "210" ||
      job_category_code_OA === "320") &&
    !(
      mail_address === "atsuya-shouta@sej.7andi.co.jp" ||
      mail_address === "shi-ni@sej.7andi.co.jp" ||
      mail_address === "kamisuwa-shino@sej.7andi.co.jp"
    ) &&
    !(
      department_code_OA === "900355" ||
      department_code_OA === "900343" ||
      department_code_OA === "900356" ||
      department_code_OA === "900353" ||
      department_code_OA === "900352" ||
      department_code_OA === "900342" ||
      department_code_OA === "900354" ||
      department_code_OA === "900341" ||
      department_code_OA === "900351"
    )
  ) {
    console.log("no chat bot");
    return apps;
  }
  // チャットボット対応
  let arr = apps;
  arr.unshift(chatBot);
  const newArr: any = [];
  arr.forEach((element: any) => {
    if (!newArr.includes(element)) {
      newArr.push(element);
    }
  });
  return newArr;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      width: "100vw",
    },
    rootTable: {
      marginTop: "5vh",
    },
    button: {
      color: "#fff",
      backgroundColor: "#ef942a",
      width: "40vw",
      height: "15vh",
      margin: "10px",
      "&:hover": {
        background: "#ef942a",
      },
      textTransform: "none",
    },
    blueButton: {
      color: "#fff",
      backgroundColor: "#007bff",
      width: "40vw",
      height: "8vh",
      margin: "10px",
      "&:hover": {
        background: "#007bff",
      },
    },
    gap: {
      height: "15px",
    },
    homePadding: {
      padding: "10px",
    },
    homeContents: {
      height: "90%",
    },
    homeMainMenu: {
      fontWeight: "bold",
      fontSize: "20px",
    },
    homeButton: {
      borderRadius: "10px",
      width: "470px",
      height: "54px",
      backgroundColor: "#00CC99",
      "&:hover": {
        background: "#00CC99",
      },
    },
    homeButtonFont: {
      color: "white",
    },
    homeMenuIcon: {
      height: "20px",
      paddingRight: "10px",
    },
    homeAccordion: {
      borderRadius: "10px",
      width: "470px",
    },
    accordionHeader: {
      backgroundColor: "#00CC99",
      color: "white",
      borderRadius: "10px",
    },
    sectionItem: {
      marginLeft: "20px",
      underline: "hover",
      variant: "caption",
      fontSize: "20px",
      cursor: "pointer",
      color: "#404040",
    },
  })
);

const Home: React.FC = (props: any) => {
  const classes = useStyles();
  const [displayMode, setDisplayMode] = React.useState("initial");
  const [apps, setApps] = React.useState([]);
  const [isAnotherLoginLoading, setIsAnotherLoginLoading] =
    React.useState(false);
  const [accessToken, setAccessToken] = React.useState("");
  const [isTokenLoading, setIsTokenLoading] = React.useState(false);

  useEffect(() => {
    // ローカルの場合はAPIサービスを参照する
    const userUrl =
      process.env.REACT_APP_URL_API! + "users/info/get-user-information";

    const moveAuthUrlFromUrl = (
      appName: string,
      paramerters: string = "",
      userJson: any
    ) => {
      if (userJson === undefined || userJson["apps"] === undefined) {
        setIsAnotherLoginLoading(false);
        setDisplayMode("noapps");
        // 権限がない場合ユーザーログに出力する
        userLogging("権限エラー", "レスポンスなし");
      } else if (userJson["apps"].indexOf(appName) === -1 && appName !== "") {
        setIsAnotherLoginLoading(false);
        setDisplayMode("noapps");
        // 権限がない場合ユーザーログに出力する
        userLogging("権限エラー", "アプリ設定なし");
      } else {
        getRequest(getAppInfo(appName, "auth-url"), {})
          .then((response) => {
            const paramertersString =
              paramerters === "" ? paramerters : "&" + paramerters;
            const url = `${getAppInfo(
              appName,
              "site-url"
            )}?token=${response}${paramertersString}`;
            window.location.href = url;
          })
          .catch(() => {
            console.log("MoveAuthUrlError2");
          });
      }
    };

    // デジタルツール画面表示
    setIsAnotherLoginLoading(true);
    getRequest(userUrl, {})
      .then((response: any) => {
        // ユーザー情報取得でエラーの場合最初に戻す
        if (response.statusCode === "900") {
          props.history.push("/");
        }
        const userJson = JSON.parse(response.dataList);
        for (const key in userJson["application_infomation"]) {
          appInfo[key] = userJson["application_infomation"][key];
        }
        setIsAnotherLoginLoading(false);
        let apps: any = [];
        if (
          props.location.state !== undefined &&
          "selectedApplication" in props.location.state &&
          props.location.state.selectedApplication !== ""
        ) {
          // アプリケーションが選択されており、デジタルツール画面を表示せずにそのままリダイレクトする場合
          // 認証後URL+パラメータへ移動する
          const appName: string = props.location.state.selectedApplication;
          moveAuthUrlFromUrl(
            appName,
            props.location.state.paramerters,
            userJson
          );
          return;
        }
        // 下記は通常デジタルツール画面
        if (userJson["apps"] === undefined) {
          // 空のユーザーはチャットボットを閲覧可能
          apps = [chatBot];
        } else if (
          userJson["mail_address"] === undefined ||
          userJson["job_category_code_OA"] === undefined
        ) {
          // メールアドレスや職種コードがないユーザーは配列を付与(検証環境向け)
          apps = userJson["apps"];
        } else {
          apps = setApplication(
            userJson["apps"],
            userJson["mail_address"],
            userJson["job_category_code_OA"],
            userJson["department_code_OA"]
          );
        }
        setDisplayMode("apps");
        setApps(apps);
        const element = document.getElementById("userInfoDiv");
        if (
          apps.includes(chatBot) &&
          element !== null &&
          userJson["department_code_OA"] !== undefined &&
          userJson["employee_no"] !== undefined &&
          userJson["job_category_code_OA"] !== undefined &&
          userJson["mail_address"] !== undefined
        ) {
          // ChatBot用のユーザーデータの設定
          element.dataset.roomTag40 = userJson["department_code_OA"];
          element.dataset.roomTag41 = userJson["job_category_code_OA"];
          element.dataset.roomTag42 = userJson["employee_no"];
          element.dataset.roomTag43 = userJson["mail_address"];
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  const isDisplayModes = (): boolean => {
    return displayMode !== "initial";
  };

  const getAppNameIcon = (appName: string, typeName: string) => {
    const applicationName: string = appInfo[appName].app_name;
    if (applicationName.match(/OPView/) || applicationName.match(/OPVIEW/)) {
      return (
        <Link className={classes.homeButtonFont}>
          <img
            src={iconDir + "op_view_icon.png"}
            alt=""
            className={classes.homeMenuIcon}
          />
          {applicationName}
        </Link>
      );
    } else if (applicationName.match(/店舗確認・One Vision/)) {
      return (
        <Link className={classes.homeButtonFont}>
          <img
            src={iconDir + "tenpo_check_icon.png"}
            alt=""
            className={classes.homeMenuIcon}
          />
          {applicationName}
        </Link>
      );
    }
    return <Link className={classes.homeButtonFont}>{applicationName}</Link>;
  };

  const setToken = (appName: string) => {
    setIsTokenLoading(true);
    let authUrl = getAppInfo(appName, "auth-url");
    if (authUrl === "") {
      return;
    }
    getRequest(authUrl, {})
      .then((response) => {
        setAccessToken(response);
        setIsTokenLoading(false);
      })
      .catch(() => {
        console.log("setAccessTokenError");
      });
  };

  const getSevenViewDemoAccordion = (app: string, index: number) => {
    // アコーディオンのみ生成を行い、各デモ環境のボタン生成処理をスキップする
    if (app !== "sevenview-demo") {
      return "";
    }
    const accordionList = appInfo[app].accordion_list;
    return (
      <React.Fragment key={index}>
        <Grid className={classes.gap}></Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.homePadding}
        >
          <Accordion
            className={classes.homeAccordion}
            onChange={(e, expanded) => {
              if (expanded) {
                setToken(app);
              }
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionHeader}
            >
              <Grid container justify="center">
                <Typography className={classes.homeMainMenu}>
                  {getAppNameIcon(app, "app-name")}
                </Typography>
              </Grid>
            </AccordionSummary>
            {isTokenLoading ? (
              <Typography align="center">{"情報取得中"}</Typography>
            ) : (
              accordionList.map((app: string, index: number) => (
                <React.Fragment key={index}>
                  <AccordionDetails>
                    <Link
                      className={classes.sectionItem}
                      onClick={() => {
                        userLogging("アプリ遷移ボタン押下:" + app, "");
                        const paramerters = props.location.state.paramerters;
                        const paramertersString =
                          paramerters === "" ? paramerters : "&" + paramerters;
                        const url = `${getAppInfo(
                          app,
                          "site-url"
                        )}?token=${accessToken}${paramertersString}`;
                        window.open(url);
                      }}
                    >
                      <Typography>{getAppInfo(app, "app-name")}</Typography>
                    </Link>
                  </AccordionDetails>
                </React.Fragment>
              ))
            )}
          </Accordion>
        </Grid>
      </React.Fragment>
    );
  };

  const isDisplayBackground = (): boolean => {
    if (displayMode === "initial") {
      return false;
    }
    return true;
  };

  const userInfoApps = (app: any) => {
    if (Object.keys(appInfo).indexOf(app) === -1) {
      return false;
    }
    return true;
  };

  const moveAuthUrl = (appName: string, paramerters: string = "") => {
    if (appName === chatBot) {
      // チャットボット ローカル環境では動作しないので注意
      // @ts-ignore
      CHORDSHIP.openChat();
      return;
    } else if (appName === "customer-360-viewer") {
      // Consumer360(家計簿PJ)への遷移を行う
      props.history.push("customer-360-viewer");
      return;
    }

    let authUrl = getAppInfo(appName, "auth-url");
    if (authUrl === "") {
      // 「auth-url」が空の場合認証トークンを取得しない
      const paramertersString: string =
        paramerters === "" ? paramerters : "?" + paramerters;
      window.location.href =
        getAppInfo(appName, "site-url") + paramertersString;
      return;
    }
    getRequest(authUrl, {})
      .then((response) => {
        let token: string = "";
        const paramertersString: string =
          paramerters === "" ? paramerters : "&" + paramerters;
        if (authUrl.indexOf("v1/user-auth/usercheck") !== -1) {
          token = response.dataList[0].token;
        } else {
          token = response;
        }
        const tokenName = getAppInfo(appName, "token");
        const url = `${getAppInfo(
          appName,
          "site-url"
        )}?${tokenName}=${token}${paramertersString}`;
        window.location.href = url;
      })
      .catch(() => {
        console.log("MoveAuthUrlError");
      });
  };

  const displayModes = () => {
    switch (displayMode) {
      case "blank":
        return <></>;
      case "noapps":
        return (
          <div>
            <p>閲覧権限がありません</p>
          </div>
        );
      case "apps":
        const appsButtons = apps.map((app: string, index) =>
          app.match(/sevenview-demo/) ? (
            getSevenViewDemoAccordion(app, index)
          ) : (
            <React.Fragment key={index}>
              {userInfoApps(app) && (
                <>
                  <Grid className={classes.gap}></Grid>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.homePadding}
                  >
                    <Button
                      className={classes.homeButton}
                      onClick={() => {
                        userLogging("アプリ遷移ボタン押下:" + app, "");
                        moveAuthUrl(app, props.location.state.paramerters);
                      }}
                    >
                      <Typography className={classes.homeMainMenu}>
                        {getAppNameIcon(app, "app-name")}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </React.Fragment>
          )
        );
        return (
          <Grid container item xs={12} justify="center">
            {appsButtons}
          </Grid>
        );
    }
  };

  return (
    <>
      {isAnotherLoginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <Grid
          container
          className={isDisplayBackground() ? classes.root : ""}
          alignItems="flex-start"
        >
          {isDisplayModes() ? (
            <Grid
              container
              alignItems="flex-start"
              justify="center"
              className={classes.homeContents}
            >
              <Grid container>
                {isDisplayBackground() ? (
                  <>
                    <div id="userInfoDiv" style={{ visibility: "hidden" }} />
                    <Header titleName={"デジタルツール"} />
                  </>
                ) : (
                  <></>
                )}
                <Grid
                  container
                  alignItems="flex-start"
                  justify="center"
                  className={classes.homeContents}
                >
                  <Grid className={classes.rootTable}>{displayModes()}</Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </>
  );
};

export default Home;
